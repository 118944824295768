exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-jaseneksi-maksu-tsx": () => import("./../../../src/pages/jaseneksi/maksu.tsx" /* webpackChunkName: "component---src-pages-jaseneksi-maksu-tsx" */),
  "component---src-pages-jaseneksi-uusinta-tsx": () => import("./../../../src/pages/jaseneksi/uusinta.tsx" /* webpackChunkName: "component---src-pages-jaseneksi-uusinta-tsx" */),
  "component---src-pages-tue-toimintaamme-aktivoi-tsx": () => import("./../../../src/pages/tue-toimintaamme/aktivoi.tsx" /* webpackChunkName: "component---src-pages-tue-toimintaamme-aktivoi-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-donation-page-tsx": () => import("./../../../src/templates/donation_page.tsx" /* webpackChunkName: "component---src-templates-donation-page-tsx" */),
  "component---src-templates-donation-thank-you-tsx": () => import("./../../../src/templates/donation_thank_you.tsx" /* webpackChunkName: "component---src-templates-donation-thank-you-tsx" */),
  "component---src-templates-form-tsx": () => import("./../../../src/templates/form.tsx" /* webpackChunkName: "component---src-templates-form-tsx" */),
  "component---src-templates-naalisivu-tsx": () => import("./../../../src/templates/naalisivu.tsx" /* webpackChunkName: "component---src-templates-naalisivu-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */)
}

